/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */

import React from 'react';
import styled from 'styled-components';

import { Container } from '@nfs-react/components';

import { useImg, useText } from 'hooks';
import { MOBILE, TABLET, DESKTOP, FROM_TABLET } from 'helpers/styles/media';

const Features = () => {
  const iconItems = [
    {
      img: 'icon-1.png',
      text: 'features_icon_1',
    },
    {
      img: 'icon-2.png',
      text: 'features_icon_2',
    },
    {
      img: 'icon-3.png',
      text: 'features_icon_3',
    },
    {
      img: 'icon-4.png',
      text: 'features_icon_4',
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Title>{useText('features_title', true)}</Title>
        <IconList>
          {iconItems.map(({ img, text }, i) => (
            <IconItem key={i} className={`item_${i + 1}`}>
              <IconImage>{useImg(img)}</IconImage>
              <IconText>{useText(text, true)}</IconText>
            </IconItem>
          ))}
        </IconList>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background: #f1f1f1;
  padding: 45px 0 73px;
  margin-top: 45px;
  margin-bottom: 45px;

  @media ${DESKTOP} {
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top: 27px solid #f1f1f1;
      border-left: 27px solid transparent;
      border-right: 27px solid transparent;
    }
  }

  @media ${TABLET} {
    &::after {
      content: '';
      position: absolute;
      top: calc(100% - 2px);
      left: calc(50% - -4px);
      transform: translateX(-50%);
      border-top: 30px solid #f1f1f1;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
    }
  }

  @media ${MOBILE} {
    padding: 45px 0;
    margin-top: 25px;
    margin-bottom: 25px;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top: 20px solid #f1f1f1;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 20px solid #f1f1f1;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
    }
  }
`;

const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 126.1%;
  margin-bottom: 62px;

  @media ${TABLET} {
    margin-bottom: 56px;
  }

  @media ${MOBILE} {
    font-size: 26px;
    margin-bottom: 30px;
  }
`;

const IconList = styled.div`
  @media ${FROM_TABLET} {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1047px;
  }

  @media ${TABLET} {
    max-width: 530px;
    flex-wrap: wrap;
    justify-content: space-between;

    .item_1,
    .item_2 {
      margin-bottom: 79px;
    }
  }
`;

const IconItem = styled.div`
  @media ${DESKTOP} {
    min-width: 145px;
    max-width: 24%;
  }

  @media ${TABLET} {
    min-width: 222px;
    max-width: 222px;
  }

  @media ${MOBILE} {
    max-width: 275px;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
      margin-bottom: 43px;
    }
  }
`;

const IconImage = styled.div`
  @media ${DESKTOP} {
    width: 87px;
    height: 87px;
    margin: 0 auto 15px;
  }

  @media ${TABLET} {
    width: 85px;
    height: 85px;
    margin: 0 auto 20px;
  }

  @media ${MOBILE} {
    width: 87px;
    height: 87px;
    margin: 0 auto 12px;
  }

  img {
    display: block;
  }
`;

const IconText = styled.div`
  text-align: center;
  color: #363f40;

  @media ${DESKTOP} {
    font-size: 18px;
    line-height: 23px;
  }

  @media ${TABLET} {
    font-size: 18px;
    font-weight: 400;
    line-height: 126.1%;
  }

  @media ${MOBILE} {
    font-size: 18px;
    line-height: 23px;
  }
`;

export default Features;
