/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Container,
  RangeSlider_v2 as RangeSliderV2,
} from '@nfs-react/components';
import { LangContext } from 'helpers/providers/LangProvider';
import { FeaturesContext } from 'helpers/providers/FeaturesProvider';

import { useText } from 'hooks';
import { TABLET, MOBILE } from 'helpers/styles/media';

const RangeSlider = ({ handleScroll, calcRef }) => {
  const { funnel_language } = useContext(LangContext);
  let currency = funnel_language === 'de' ? '€' : '$';
  const title = useText('rSlider__title', true);
  const profitTitle = useText('rSlider__profitTitle', true);
  const features = useContext(FeaturesContext);
  const isCurrencyEuro = features.includes('currency_euro');
  const isCurrencyAfterAmount =
    funnel_language === 'de' || features.includes('currency_after_amount');
  if (isCurrencyEuro) {
    currency = '€';
  }
  const isMinAmount = features.includes('min_amount');
  const minAmount = isMinAmount ? 100 : null;
  return (
    <RangeSection ref={calcRef}>
      <Container>
        <RangeSliderV2
          currency={currency}
          title={title}
          potentialTitle={profitTitle}
          wrapperStyles={sliderWrapperCss}
          titleStyles={sliderTitleCss}
          buttonStyles={sliderButtonStyles}
          trackStyles={sliderTrackStyles}
          valueStyles={sliderValueStyles}
          handleClickButton={handleScroll}
          isCurrencyBeforeAmount={!isCurrencyAfterAmount}
          minAmount={minAmount}
        />
      </Container>
    </RangeSection>
  );
};

const RangeSection = styled.section`
  position: relative;
  padding: 45px 0 45px;

  @media ${MOBILE} {
    padding: 25px 0 25px;
  }
`;

const sliderWrapperCss = css`
  position: relative;
  max-width: 768px;
  background: #fff;
  border: 4px solid #fff;
  box-shadow: 0 5px 32px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 28px 32px 94px;
  margin: 0 auto 50px;

  @media ${TABLET} {
    max-width: 100%;
    padding: 28px 32px 96px;
    margin-bottom: 50px;
  }

  @media ${MOBILE} {
    padding: 21px 16px 45px;
    margin-bottom: 28px;
    max-width: 311px;
  }
`;

const sliderTitleCss = css`
  text-align: center;
  margin: 0 auto 60px;
  color: #000;
  margin-bottom: 67px;
  font-size: 27px;
  font-weight: 700;
  line-height: 126.1%;

  @media ${TABLET} {
    margin: 0 auto 67px;
  }

  @media ${MOBILE} {
    font-size: 18px;
    padding: 0 5px;
    margin: 0 auto 42px;
  }
`;

const sliderButtonStyles = css`
  background: var(--primary-color-1);
  width: 294px;
  color: #fff;

  @media ${TABLET} {
    width: 297px;
    height: 107px;
  }

  @media ${MOBILE} {
    width: 162px;
    height: 64px;
    bottom: -32px;
    border-radius: 6px;
  }

  div {
    :first-child {
      color: #fff;
      line-height: 136.1%;
      margin-top: 2px;
      letter-spacing: initial;
      font-weight: 400;

      @media ${MOBILE} {
        font-size: 10px;
      }
    }
  }
`;

const sliderValueStyles = css`
  @media ${TABLET} {
    font-size: 43px;
  }

  @media ${MOBILE} {
    font-size: 25px;
  }
`;

const sliderTrackStyles = css`
  .rc-slider-handle {
    font-family: ${({ theme }) => theme.FF_MONTSERRAT};
    top: -7px;
    font-size: 15px;
    color: #fff;
    background: #020202;
    width: 106px;
    height: 42px;
    border: transparent;

    &:after,
    &:before {
      content: '';
      padding: 3px;
      top: 17px;
      border-color: #fff;
      border-width: 0 2px 2px 0;

      @media ${TABLET} {
        top: 16px;
        padding: 3px;
      }

      @media ${MOBILE} {
        top: 9px;
      }
    }

    @media ${MOBILE} {
      width: 72px;
      height: 29px;
      border-radius: 3px;
      font-size: 11px;
    }
  }

  .rc-slider-track,
  .rc-slider-rail,
  .rc-slider-step {
    height: 18px;

    @media ${MOBILE} {
      height: 7px;
    }
  }

  .rc-slider-track {
    background: #e0e0e0;
  }

  .rc-slider-mark {
    left: 8px;
    @media ${MOBILE} {
      top: 22px;
      left: 4px;
    }
  }

  .rc-slider-mark-text {
    font-weight: 300;
    color: #c6c6c6;

    @media ${MOBILE} {
      font-size: 11px;
    }
  }
`;

RangeSlider.propTypes = {
  handleScroll: PropTypes.func.isRequired,
  calcRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.shape({ root: PropTypes.string.isRequired }),
    }),
  ]).isRequired,
};

export default RangeSlider;
