/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Container } from '@nfs-react/components';

import { useText, useImg } from 'hooks';
import { MOBILE, TABLET, DESKTOP } from 'helpers/styles/media';
import { ButtonStyles } from '../UI/UI';

const CTA = ({ scrollToForm, brand }) => {
  const brandText = () => {
    return (string) => {
      const replacedText = brand;
      return string.replace(/#BT/g, replacedText);
    };
  };

  return (
    <Wrapper>
      <Container>
        <ButtonRow>
          <Button onClick={scrollToForm}>{useText('bottom__btn', true, brandText())}</Button>
        </ButtonRow>
        <Payments>{useImg('cta-payments.png', 'cta-payments')}</Payments>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 45px 0;

  @media ${MOBILE} {
    padding: 25px 0;
  }
`;

const ButtonRow = styled.div`
  text-align: center;

  @media ${DESKTOP} {
    margin-bottom: 21px;
  }

  @media ${TABLET} {
    margin-bottom: 23px;
  }

  @media ${MOBILE} {
    margin-bottom: 24px;
  }
`;

const Button = styled.div`
  ${ButtonStyles};

  margin: 0 auto 28px;
  height: 70px;

  @media ${DESKTOP} {
    max-width: 900px;
    padding: 0px 77px;
  }

  @media ${TABLET} {
    max-width: 532px;
    padding: 0px 55px;
    font-size: 18px;
  }

  @media ${MOBILE} {
    max-width: 340px;
    font-size: 15px;
    padding: 0 10px;
  }
`;

const Payments = styled.div`
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
`;

CTA.propTypes = {
  scrollToForm: PropTypes.bool.isRequired,
  brand: PropTypes.string,
};

export default CTA;
