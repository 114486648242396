/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */

import React from 'react';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import { useImg, useText } from 'hooks';

import { MainForm } from 'helpers/form';

import { MOBILE, DESKTOP, TO_DESKTOP } from 'helpers/styles/media';
import { ButtonStyles, FormWrapperStyles } from '../UI/UI';

const Form = ({ formRef, brand }) => {
  const brandText = () => {
    return (string) => {
      const replacedText = brand;
      return string.replace(/#BT/g, replacedText);
    };
  };

  return (
    <FormWrapper ref={formRef}>
      <Top>{useText('form__title', true, brandText())}</Top>
      <Body>
        <MainForm
          buttonText={useText('form__buttonText', true)}
          buttonCss={buttonStyles}
        />
        <Text>{useText('form__text', true, brandText())}</Text>
      </Body>
      <Payments>{useImg('form-payments.png', 'form__payments')}</Payments>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  ${FormWrapperStyles}

  @media ${DESKTOP} {
    position: absolute;
    top: 48px;
    right: calc(50% - 590px);
  }

  @media ${TO_DESKTOP} {
    position: relative;
    margin: -89px auto 20px;
  }

  @media ${MOBILE} {
    margin: 20px auto 30px;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 89px;
  font-weight: 700;
  color: #00263a;
  font-size: 21px;
  background: #efefef;
  text-align: center;
  padding: 0 10px;

  @media ${MOBILE} {
    min-height: 60px;
    font-size: 18px;
  }
`;

const Body = styled.div`
  padding: 18px 16px 16px 16px;
  background: #fff;
  color: #373737;
`;

const buttonStyles = css`
  ${ButtonStyles}

  min-height: 73px;
  font-size: 22px;
  font-weight: 700;
  border-radius: 0;
`;

const Text = styled.div`
  color: #696969;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  &:lang(pt) {
    font-size: 13px;
  }

  @media ${MOBILE} {
    font-size: 13px;
    &:lang(pt) {
      font-size: 12px;
    }
  }
`;

const Payments = styled.div`
  background: #efefef;
  min-height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transform: translate(0px, 4px);
  }
`;

Form.propTypes = {
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.shape({ root: PropTypes.string.isRequired }),
    }),
  ]).isRequired,
  brand: PropTypes.string,
};

export default Form;
