/* eslint-disable import/no-unresolved */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Container } from '@nfs-react/components';

import { useText } from 'hooks';
import { FeaturesContext } from 'helpers/providers/FeaturesProvider';

import { Calculator as Counter } from 'helpers/components';
import { LangContext } from 'helpers/providers/LangProvider';
import { MOBILE, TABLET, DESKTOP } from 'helpers/styles/media';

const Calculator = ({ calcRef }) => {
  const { funnel_language } = useContext(LangContext);
  let currency = funnel_language === 'de' ? '€' : '$';
  const features = useContext(FeaturesContext);
  const isCurrencyEuro = features.includes('currency_euro');
  const minAmount = features.includes('min_amount') ? 100 : 250;
  const isCurrencyAfterAmount =
    funnel_language === 'de' || features.includes('currency_after_amount');
  if (isCurrencyEuro) {
    currency = '€';
  }

  return (
    <Wrapper>
      <StyledContainer>
        <Title ref={calcRef}>{useText('calc__title', true)}</Title>
        <Counter
          currency={currency}
          investmentText={useText('calc__investment', true)}
          profitText={useText('calc__profit', true)}
          buttonText={useText('calc__button', true)}
          buttonStyles={buttonStyles}
          investmentTitleStyles={investmentTitleStyles}
          profitTitleStyles={profitTitleStyles}
          investmentBlockStyles={investmentBlockStyles}
          profitBlockStyles={profitBlockStyles}
          inputStyles={inputStyles}
          currencyStyles={currencyStyles}
          errorStyles={errorStyles}
          primaryColor='var(--primary-color-1)'
          isCurrencyBeforeAmount={!isCurrencyAfterAmount}
          minDeposit={minAmount}
        />
      </StyledContainer>
    </Wrapper>
  );
};

const StyledContainer = styled(Container)`
  @media ${DESKTOP} {
    max-width: 1170px;
  }

  @media ${MOBILE} {
    padding: 0;
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 45px 0 45px;

  @media ${MOBILE} {
    padding: 25px 0;
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 40px;
  color: #000;
  font-size: 26px;
  font-weight: 700;
  line-height: 126.1%;
  margin-bottom: 40px;

  @media ${TABLET} {
    font-size: 24px;
  }

  @media ${MOBILE} {
    font-size: 23px;
    max-width: 360px;
    margin: 0 auto 25px;
  }
`;

const buttonStyles = css`
  color: var(--text-color);
  text-align: center;
  font-size: 18px;
  line-height: 126.1%;
  text-transform: uppercase;

  @media ${DESKTOP} {
    &:lang(de) {
      padding: 0 15px;
    }
  }

  @media ${TABLET} {
    width: 106px;
    height: 35px;
    border-radius: 27px;
    font-size: 11px;
    line-height: 126.1%;

    &:lang(de) {
      max-width: 140px;
      padding: 0 13px;
    }
  }

  @media ${MOBILE} {
    &:lang(de) {
      max-width: 140px;
      padding: 0 10px;
    }
  }
`;

const investmentTitleStyles = css`
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 126.1%;

  @media ${TABLET} {
    font-size: 13px;
    font-weight: 700;
    line-height: 126.1%;
    margin-bottom: 28px;
  }

  @media ${MOBILE} {
    font-size: 18px;
    font-weight: 700;
    line-height: 126.1%;
    margin-bottom: 41px;
  }
`;

const profitTitleStyles = css`
  color: var(--text-color);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 126.1%;

  @media ${TABLET} {
    font-size: 13px;
    font-weight: 700;
    line-height: 126.1%;
    margin-bottom: 28px;
  }

  @media ${MOBILE} {
    font-size: 18px;
    font-weight: 700;
    line-height: 126.1%;
    margin-bottom: 40px;
  }
`;

const investmentBlockStyles = css`
  padding: 52px 57px 68px 56px;

  @media ${TABLET} {
    padding: 34px 37px 40px 36px;
    height: 153px;
  }

  @media ${MOBILE} {
    padding: 39px 15px 55px;
  }
`;

const profitBlockStyles = css`
  padding: 52px 57px 68px 56px;

  @media ${TABLET} {
    padding: 34px 37px 40px 36px;
    height: 153px;
  }

  @media ${MOBILE} {
    padding: 39px 15px 55px;
  }
`;

const inputStyles = css`
  @media ${TABLET} {
    height: 33px;
    font-size: 12px;
  }
`;

const currencyStyles = css`
  @media ${TABLET} {
    font-size: 12px;
  }
`;

const errorStyles = css`
  @media ${TABLET} {
    font-size: 14px;
  }
`;

Calculator.propTypes = {
  calcRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.shape({ root: PropTypes.string.isRequired }),
    }),
  ]).isRequired,
};

export default Calculator;
