/* eslint-disable import/no-unresolved */
import { css } from 'styled-components';
import { MOBILE } from 'helpers/styles/media';

export const ButtonStyles = css`
  cursor: pointer;
  background-color: var(--primary-color-1);
  border-radius: 46px;
  text-align: center;
  color: var(--text-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 126.1%;
  letter-spacing: 0.4px;
  margin: 0 auto;
  box-shadow: 0px 4.11732px 9.26398px 0px rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;

  &:hover {
    transform: scale(1.07);
  }
`;

export const FormWrapperStyles = css`
  background-color: #ffffff;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 10;
  max-width: 369px;

  @media ${MOBILE} {
    max-width: 336px;
  }
`;
