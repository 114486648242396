/* eslint-disable import/no-unresolved */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container } from '@nfs-react/components';

import { useText, useImg } from 'hooks';
import { DESKTOP, TABLET, MOBILE, TO_DESKTOP } from 'helpers/styles/media';
import { FeaturesContext } from 'helpers/providers/FeaturesProvider';

const Quotes = ({ prefix }) => {
  const features = useContext(FeaturesContext);
  const removeStripText = features.includes('remove_strip_text');
  return (
    <>
      <Wrapper removeStripText={removeStripText}>
        <Container>
          <Block>
            {!removeStripText && <Text>{useText('quotes_content', true)}</Text>}
            <Arrow>{useImg(`arrow${prefix}.svg`, 'arrow')}</Arrow>
          </Block>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background: var(--primary-color-2);

  @media ${DESKTOP} {
    padding: 37px 0;
    margin-bottom: 87px;

    ${({ theme }) => theme.hasGdpr && 'margin-bottom: 135px;'}

    ${({ removeStripText }) =>
    removeStripText &&
      `
        height: 102px;
    `};
  }

  @media ${TABLET} {
    padding: 36px 0 127px;

    ${({ removeStripText }) =>
    removeStripText &&
      `
        height: 229px;
    `};
  }

  @media ${MOBILE} {
    padding: 28px 0;

    ${({ removeStripText }) =>
    removeStripText &&
      `
        height: 107px;
        margin-bottom: -35px;
    `};
  }
`;

const Block = styled.div`
  position: relative;
  display: flex;

  @media ${DESKTOP} {
    max-width: 697px;
    transform: translate(20px, 0px);
  }

  @media ${TO_DESKTOP} {
    justify-content: center;
  }
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  max-width: 560px;
  width: 100%;

  @media ${TABLET} {
    font-size: 26px;
    max-width: 370px;
  }

  @media ${MOBILE} {
    max-width: 260px;
    font-size: 20px;
    &:lang(pt) {
      font-size: 19px;
    }
  }
`;

const Arrow = styled.div`
  position: absolute;

  @media ${DESKTOP} {
    right: -55px;
    top: -83px;
  }

  @media ${TABLET} {
    top: -66px;
    left: 75px;
    transform: rotate(125deg);
  }

  @media ${MOBILE} {
    width: 110px;
    top: -46px;
    left: -40px;
    transform: rotate(125deg);
  }
`;

Quotes.propTypes = {
  prefix: PropTypes.string,
};

export default Quotes;
