/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container } from '@nfs-react/components';

import { useText } from 'hooks';
import { MOBILE, DESKTOP } from 'helpers/styles/media';
import { ButtonStyles } from '../UI/UI';

const Article = ({ handleScroll, prefix }) => {
  return (
    <Wrapper>
      <ArticleContainer>
        <Title>{useText(`article__title${prefix}`, true)}</Title>
        <Text>{useText('article__text_1', true)}</Text>
        <Button onClick={handleScroll}>
          {useText('article__btn_1', true)}
        </Button>
        <Text>{useText('article__text_2', true)}</Text>
        <Button onClick={handleScroll}>
          {useText('article__btn_2', true)}
        </Button>
      </ArticleContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 45px 0 5px;

  @media ${MOBILE} {
    padding: 25px 0;
  }
`;

const ArticleContainer = styled(Container)`
  @media ${DESKTOP} {
    max-width: 777px;
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 40px;
  color: #000;
  font-size: 32px;
  font-weight: 700;
  line-height: 126.1%;

  @media ${MOBILE} {
    font-size: 25px;
    margin-bottom: 25px;
  }
`;

const Text = styled.div`
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 126.1%;

  p {
    margin-bottom: 40px;

    @media ${MOBILE} {
      margin-bottom: 25px;
    }
  }

  @media ${MOBILE} {
    padding: 0 10px;
    font-size: 16px;
  }
`;

const Button = styled.div`
  ${ButtonStyles}

  margin: 0 auto 40px;
  min-height: 70px;
  width: 400px;
  height: 70px;

  @media ${MOBILE} {
    width: 340px;
    margin: 0 auto 25px;
  }
`;

Article.propTypes = {
  handleScroll: PropTypes.func.isRequired,
  prefix: PropTypes.string,
};

export default Article;
